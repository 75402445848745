/* eslint-disable import/no-cycle */
import { action, makeObservable, observable } from 'mobx';
import React from 'react';
import {
  AccountService,
  IdentifierService,
  PersonPrivilegesUpdate,
  PersonService,
  IdentifierInfoWithAccountInfo,
  SubAccountsListItem,
  SubAccountsListItemPaginatedList,
  UserRole
} from '../../swagger/api';
import BaseStore from '../base-stores/BaseStore';
import { RootStore } from '../RootStore';

export default class EditUserStore extends BaseStore {
  isEditUserModalVisible: boolean = false;

  accountsWithAccessRights?: SubAccountsListItem[] = [];

  searchVal: string = '';

  accountPageSize: number = 0;

  accountPageNumber: number = 1;

  filteredAccountCount: number = 0;

  selectedUserRole?: UserRole;

  selectedAccountIds: React.Key[] = [];

  productAccessProduct?: IdentifierInfoWithAccountInfo;

  isAnimationOpen: boolean = false;

  resetUserRole = (editedPersonOriginRole: UserRole) => {
    this.selectedUserRole = editedPersonOriginRole;
  };

  resetAccounts = (editedPersonOriginAccountIds: string[]) => {
    this.selectedAccountIds = (editedPersonOriginAccountIds as React.Key[]).slice();
  };

  addSelectedAccountIds = (selectedAccountIds: React.Key[]) => {
    selectedAccountIds.forEach((id) => {
      if (this.selectedAccountIds.indexOf(id) === -1) {
        this.selectedAccountIds.push(id);
      }
    });
  };

  removeSelectedAccountIds = (deselectedAccountId: React.Key[]) => {
    deselectedAccountId.forEach((id) => {
      const index = this.selectedAccountIds.indexOf(id);
      this.selectedAccountIds.splice(index, 1);
    });
  };

  selectOrDeselectAll = (selected: boolean, selectedAccounts: SubAccountsListItem[]) => {
    const selectedAccountsWithReferenceToCurrentUser: React.Key[] = [];
    selectedAccounts.forEach((account: SubAccountsListItem) => {
      if (this.rootStore.userStore.person?.accountIds?.includes(account.id!)) {
        selectedAccountsWithReferenceToCurrentUser.push(account.id!);
      }
    });
    if (selected) {
      this.addSelectedAccountIds(selectedAccountsWithReferenceToCurrentUser);
    } else {
      this.removeSelectedAccountIds(selectedAccountsWithReferenceToCurrentUser);
    }
  };

  setSelectedUserRole = (userRole: UserRole) => {
    this.selectedUserRole = userRole;
  };

  closeEditUserModal = async () => {
    this.setIsAnimationOpen(false);
    // eslint-disable-next-line no-promise-executor-return
    await new Promise((r) => setTimeout(r, 500));
    this.isEditUserModalVisible = false;
  };

  setIsAnimationOpen = (isOpen: boolean) => {
    this.isAnimationOpen = isOpen;
  };

  openEditUserModal = () => {
    this.setIsAnimationOpen(true);
    this.isEditUserModalVisible = true;
  };

  setSearchVal = (searchValue: string) => {
    this.searchVal = searchValue;
  };

  setCurrentAccountPage = (pageNumber: number) => {
    this.accountPageNumber = pageNumber;
  };

  getAccountsWithAccessRights = (editedPersonId: string) => {
    const searchString = this.searchVal;
    const pageNumber = this.accountPageNumber;
    const promise = AccountService.getSiblingAccountsRelatedToCurrentAndEditedPersonAsync({
      editedPersonId,
      searchString,
      pageNumber
    });

    promise.then((value: SubAccountsListItemPaginatedList) => {
      this.accountsWithAccessRights = value.paginatedItems;
      this.accountPageSize = value.pageSize!;
      this.filteredAccountCount = value.filteredCount!;
    });
    return promise;
  };

  submitUserAcessRightsChange = (personId: string) => {
    const role = this.selectedUserRole;
    const accountsIds = this.selectedAccountIds as string[];
    const body: PersonPrivilegesUpdate = { role, accountsIds };
    const promise = PersonService.personPrivilegesUpdate({ personId, body });
    return promise;
  };

  getProductsForProductAccessUser = (personId: string) => {
    const promise = IdentifierService.getIdentifierByPersonId({ personId });
    promise.then((value: IdentifierInfoWithAccountInfo) => {
      this.productAccessProduct = value;
    });
    return promise;
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      isAnimationOpen: observable,
      isEditUserModalVisible: observable,
      accountsWithAccessRights: observable,
      searchVal: observable,
      accountPageSize: observable,
      selectedUserRole: observable,
      selectedAccountIds: observable,
      productAccessProduct: observable,
      filteredAccountCount: observable,
      accountPageNumber: observable,
      closeEditUserModal: action,
      openEditUserModal: action,
      getAccountsWithAccessRights: action,
      setSearchVal: action,
      setCurrentAccountPage: action,
      submitUserAcessRightsChange: action,
      setSelectedUserRole: action,
      addSelectedAccountIds: action,
      removeSelectedAccountIds: action,
      selectOrDeselectAll: action,
      resetAccounts: action,
      resetUserRole: action,
      getProductsForProductAccessUser: action,
      setIsAnimationOpen: action
    });
  }
}
